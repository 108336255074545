import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import FileUploadTab from './tabs/FileUploadTab';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useBreakpointValue } from '@chakra-ui/react';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const Docai = () => {
  const [holidays, setHolidays] = useState([]);
  const { isAuthenticated, checkAuth } = useAuth();
  const tabSize = useBreakpointValue({ base: 'xs', md: 'md' });
  const paddingX = useBreakpointValue({ base: 2, md: 9 });
  const tabFontSize = useBreakpointValue({ base: 'xs', md: 'md' });

  useEffect(() => {
    if (isAuthenticated) {
      axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
        .then(response => setHolidays(response.data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [isAuthenticated]);

  const handleAddHoliday = async () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true });
      setHolidays([...holidays, response.data]);
    } catch (error) {
      console.error('Error adding action:', error);
    }
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box flex="1">
        <Tabs variant="enclosed">
          <TabList isFitted px={paddingX} size={tabSize}>
            <Tab fontSize={tabFontSize}>Ai DOC</Tab>
          </TabList>

          <TabPanels px={paddingX}>
            <TabPanel overflow="auto">
              <FileUploadTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Footer />
    </Box>
  );
};

export default Docai;
