
//рабочий 6 окт 2024
import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, RadioGroup, Radio, Stack, Select, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const [sliderValue, setSliderValue] = useState(1);
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const options = [
    { value: 'Захватывающий заголовок: Привлеките внимание с первых слов.', description: 'Привлеките внимание с первых слов.' },
    { value: 'Структура "перевернутой пирамиды": Начните с самой важной информации.', description: 'Начните с самой важной информации.' },
    { value: 'Короткие абзацы: Облегчите восприятие текста визуально.', description: 'Облегчите восприятие текста визуально.' },
    { value: 'Подзаголовки: Разбейте текст на легко сканируемые секции.', description: 'Разбейте текст на легко сканируемые секции.' },
    { value: 'Истории и примеры: Иллюстрируйте ключевые моменты.', description: 'Иллюстрируйте ключевые моменты.' },
    { value: 'Эмоциональная связь: Обращайтесь к чувствам читателя.', description: 'Обращайтесь к чувствам читателя.' },
    { value: 'Призывы к действию: Побуждайте читателя к конкретным шагам.', description: 'Побуждайте читателя к конкретным шагам.' },
    { value: 'Уникальное торговое предложение: Четко объясните, чем вы отличаетесь.', description: 'Четко объясните, чем вы отличаетесь.' },
    { value: 'Разговорный стиль: Пишите так, как говорите.', description: 'Пишите так, как говорите.' },
    { value: 'Решение проблем: Покажите, как ваш продукт или услуга помогает читателю.', description: 'Покажите, как ваш продукт или услуга помогает читателю.' },
    { value: 'Инструкция: Опишите техническую документацию сухи и емким текстом.', description: 'Опишите техническую документацию сухи и емким текстом.' },
  ];

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleSelectChange = (e, index) => {
    const newData = [...data];
    newData[index].selectedOption = e.target.value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        promotionTypes: [
          action.promotionType7Days || 'SMS',
          action.promotionType5Days || 'SMS',
          action.promotionType3Days || 'SMS',
          action.promotionType1Day || 'SMS',
          action.promotionTypeEventDay || 'SMS',
          action.promotionTypeAfter2Days || 'SMS',
        ],
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const promotionTypeField = `promotionType${field.replace('promotionText', '')}`;
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field,
        promotionType: action[promotionTypeField] || 'SMS',
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста для конкретного поля
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16);
  };

  const handlePromotionTypeChange = (value, index, field) => {
    const newData = [...data];
    newData[index][`promotionType${field.replace('promotionText', '')}`] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleCopyText = (index, field) => {
    const text = data[index][field] === 'none' ? '' : data[index][field];
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'Текст скопирован',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error('Ошибка копирования текста:', err);
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const multipliers = [1, 5, 10];
  const heightMultiplier = multipliers[sliderValue - 1];

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Тема контента</Th>
            <Th color="green.200">1 Вариант контента</Th>
            <Th color="green.200">2 Вариант контента</Th>
            <Th color="green.200">3 Вариант контента</Th>
            <Th color="green.200">4 вариант контента</Th>
            <Th color="green.200">5 вариант контента</Th>
            <Th color="green.200">6 вариант контента</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                  <Input
                      placeholder="Опиши тему для контента"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      mb={3}
                      _focus={{ boxShadow: "none" }}
                    />
                    <Select
                      mb={6}
                      size="sm"
                      value={item.selectedOption || options[0].value}
                      onChange={(e) => handleSelectChange(e, index)}
                      width="200px"
                      variant="unstyled"
                      color="gray.500"
                      iconColor="green.500"
                    >
                      {options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.value.split(':')[0]}
                          <Text as="span" fontSize="xs" color="gray.500">
                            :{option.description}
                          </Text>
                        </option>
                      ))}
                    </Select>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                    </Box>
                
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days' },
                  { field: 'promotionText5Days' },
                  { field: 'promotionText3Days' },
                  { field: 'promotionText1Day' },
                  { field: 'promotionTextEventDay' },
                  { field: 'promotionTextAfter2Days' }
                ].map(({ field }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <RadioGroup
                            value={item[`promotionType${field.replace('promotionText', '')}`] || 'SMS'}
                            onChange={(value) => handlePromotionTypeChange(value, index, field)}
                            size="sm"
                          >
                            <Stack direction="row" spacing={2}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Пост">Пост</Radio>
                              <Radio value="Статья">Статья</Radio>
                            </Stack>
                          </RadioGroup>
                          <IconButton
                            icon={<FaCopy size="12px" />}
                            aria-label="Copy"
                            ml={2}
                            size="sm"
                            onClick={() => handleCopyText(index, field)}
                            color="gray.500"
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder='Здесь будет созданный текст от искусственного интеллекта после того как ты нажмешь на кнопку фиолетовая "Волшебная палочка" или синии "Стрелочки"'
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Уточнение для ИИ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Например: используй новогодние смайлики и скидку -20% с 1 по 9 января" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveComment}>
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    {/*  <Box
      position="fixed"
      bottom={{ base: "30px", md: "0" }} // 30px отступ для мобильных устройств
      width="50px" // Фиксированная ширина 50px
      left="calc(50% - 25px)" // Центрирование по горизонтали
      bg="transparent"
      p={4}
    >
      <Slider
        min={1}
        max={3}
        step={1}
        value={sliderValue}
        onChange={handleSliderChange}
        orientation="horizontal" // Горизонтальная ориентация
      >
        <SliderTrack bg="rgba(255, 255, 255, 0.1)" borderRadius="15px">
          <SliderFilledTrack bg="rgba(74, 178, 166, 0.7)" />
        </SliderTrack>
        <SliderThumb boxSize={6}>
          <motion.div
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 0.6, repeat: Infinity, repeatDelay: 2 }}
          >
            <Box h="6" w="6" bg="rgba(255, 206, 0, 0.9)" borderRadius="full" />
          </motion.div>
        </SliderThumb>
      </Slider>
    </Box> */}

    </Box>
  );
};

export default ActionGenerateTag;






/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, RadioGroup, Radio, Stack, Select, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const options = [
    { value: 'Захватывающий заголовок: Привлеките внимание с первых слов.', description: 'Привлеките внимание с первых слов.' },
    { value: 'Структура "перевернутой пирамиды": Начните с самой важной информации.', description: 'Начните с самой важной информации.' },
    { value: 'Короткие абзацы: Облегчите восприятие текста визуально.', description: 'Облегчите восприятие текста визуально.' },
    { value: 'Подзаголовки: Разбейте текст на легко сканируемые секции.', description: 'Разбейте текст на легко сканируемые секции.' },
    { value: 'Истории и примеры: Иллюстрируйте ключевые моменты.', description: 'Иллюстрируйте ключевые моменты.' },
    { value: 'Эмоциональная связь: Обращайтесь к чувствам читателя.', description: 'Обращайтесь к чувствам читателя.' },
    { value: 'Призывы к действию: Побуждайте читателя к конкретным шагам.', description: 'Побуждайте читателя к конкретным шагам.' },
    { value: 'Уникальное торговое предложение: Четко объясните, чем вы отличаетесь.', description: 'Четко объясните, чем вы отличаетесь.' },
    { value: 'Разговорный стиль: Пишите так, как говорите.', description: 'Пишите так, как говорите.' },
    { value: 'Решение проблем: Покажите, как ваш продукт или услуга помогает читателю.', description: 'Покажите, как ваш продукт или услуга помогает читателю.' },
    { value: 'Инструкция: Опишите техническую документацию сухи и емким текстом.', description: 'Опишите техническую документацию сухи и емким текстом.' },
  ];

  const handleSelectChange = (e, index) => {
    const newData = [...data];
    newData[index].selectedOption = e.target.value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        promotionTypes: [
          action.promotionType7Days || 'SMS',
          action.promotionType5Days || 'SMS',
          action.promotionType3Days || 'SMS',
          action.promotionType1Day || 'SMS',
          action.promotionTypeEventDay || 'SMS',
          action.promotionTypeAfter2Days || 'SMS',
        ],
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const promotionTypeField = `promotionType${field.replace('promotionText', '')}`;
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field,
        promotionType: action[promotionTypeField] || 'SMS',
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста для конкретного поля
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16);
  };

  const handlePromotionTypeChange = (value, index, field) => {
    const newData = [...data];
    newData[index][`promotionType${field.replace('promotionText', '')}`] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleCopyText = (index, field) => {
    const text = data[index][field] === 'none' ? '' : data[index][field];
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'Текст скопирован',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error('Ошибка копирования текста:', err);
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Тема контента</Th>
            <Th color="green.200">1 Вариант контента</Th>
            <Th color="green.200">2 Вариант контента</Th>
            <Th color="green.200">3 Вариант контента</Th>
            <Th color="green.200">4 вариант контента</Th>
            <Th color="green.200">5 вариант контента</Th>
            <Th color="green.200">6 вариант контента</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                    </Box>
                    <Select
                      mt={2}
                      size="sm"
                      value={item.selectedOption || options[0].value}
                      onChange={(e) => handleSelectChange(e, index)}
                      width="200px"
                      variant="unstyled"
                      color="gray.500"
                      iconColor="green.500"
                    >
                      {options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.value.split(':')[0]}
                          <Text as="span" fontSize="xs" color="gray.500">
                            :{option.description}
                          </Text>
                        </option>
                      ))}
                    </Select>
                    <Input
                      placeholder="Опиши тему для контента"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      mt={2}
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days' },
                  { field: 'promotionText5Days' },
                  { field: 'promotionText3Days' },
                  { field: 'promotionText1Day' },
                  { field: 'promotionTextEventDay' },
                  { field: 'promotionTextAfter2Days' }
                ].map(({ field }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <RadioGroup
                            value={item[`promotionType${field.replace('promotionText', '')}`] || 'SMS'}
                            onChange={(value) => handlePromotionTypeChange(value, index, field)}
                            size="sm"
                          >
                            <Stack direction="row" spacing={2}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Пост">Пост</Radio>
                              <Radio value="Статья">Статья</Radio>
                            </Stack>
                          </RadioGroup>
                          <IconButton
                            icon={<FaCopy size="12px" />}
                            aria-label="Copy"
                            ml={2}
                            size="sm"
                            onClick={() => handleCopyText(index, field)}
                            color="gray.500"
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder='Здесь будет созданный текст от искусственного интеллекта после того как ты нажмешь на кнопку фиолетовая "Волшебная палочка" или синии "Стрелочки"'
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Уточнение для ИИ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Например: используй новогодние смайлики и скидку -20% с 1 по 9 января" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveComment}>
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;


*/




/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, RadioGroup, Radio, Stack, Select, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash, FaCopy } from 'react-icons/fa';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const options = [
    { value: 'Захватывающий заголовок: Привлеките внимание с первых слов.', description: 'Привлеките внимание с первых слов.' },
    { value: 'Структура "перевернутой пирамиды": Начните с самой важной информации.', description: 'Начните с самой важной информации.' },
    { value: 'Короткие абзацы: Облегчите восприятие текста визуально.', description: 'Облегчите восприятие текста визуально.' },
    { value: 'Подзаголовки: Разбейте текст на легко сканируемые секции.', description: 'Разбейте текст на легко сканируемые секции.' },
    { value: 'Истории и примеры: Иллюстрируйте ключевые моменты.', description: 'Иллюстрируйте ключевые моменты.' },
    { value: 'Эмоциональная связь: Обращайтесь к чувствам читателя.', description: 'Обращайтесь к чувствам читателя.' },
    { value: 'Призывы к действию: Побуждайте читателя к конкретным шагам.', description: 'Побуждайте читателя к конкретным шагам.' },
    { value: 'Уникальное торговое предложение: Четко объясните, чем вы отличаетесь.', description: 'Четко объясните, чем вы отличаетесь.' },
    { value: 'Разговорный стиль: Пишите так, как говорите.', description: 'Пишите так, как говорите.' },
    { value: 'Решение проблем: Покажите, как ваш продукт или услуга помогает читателю.', description: 'Покажите, как ваш продукт или услуга помогает читателю.' },
    { value: 'Инструкция: Опишите техническую документацию сухи и емким текстом.', description: 'Опишите техническую документацию сухи и емким текстом.' },
  ];

  const handleSelectChange = (e, index) => {
    const newData = [...data];
    newData[index].selectedOption = e.target.value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        promotionTypes: [
          action.promotionType7Days || 'SMS',
          action.promotionType5Days || 'SMS',
          action.promotionType3Days || 'SMS',
          action.promotionType1Day || 'SMS',
          action.promotionTypeEventDay || 'SMS',
          action.promotionTypeAfter2Days || 'SMS',
        ],
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const promotionTypeField = `promotionType${field.replace('promotionText', '')}`;
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        selectedOption: action.selectedOption || options[0].value,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field,
        promotionType: action[promotionTypeField] || 'SMS',
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста для конкретного поля
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16);
  };

  const handlePromotionTypeChange = (value, index, field) => {
    const newData = [...data];
    newData[index][`promotionType${field.replace('promotionText', '')}`] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleCopyText = (index, field) => {
    const text = data[index][field] === 'none' ? '' : data[index][field];
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'Текст скопирован',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error('Ошибка копирования текста:', err);
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название события</Th>
            <Th color="green.200">Текст акции за 7 дней до</Th>
            <Th color="green.200">Текст акции за 5 дней до</Th>
            <Th color="green.200">Текст акции за 3 дня до</Th>
            <Th color="green.200">Текст акции за 1 день до</Th>
            <Th color="green.200">Текст акции в день праздника</Th>
            <Th color="green.200">Текст акции после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                    </Box>
                    <Select
                      mt={2}
                      size="sm"
                      value={item.selectedOption || options[0].value}
                      onChange={(e) => handleSelectChange(e, index)}
                    >
                      {options.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.value.split(':')[0]}
                          <Text as="span" fontSize="xs" color="gray.500">:{option.description}</Text>
                        </option>
                      ))}
                    </Select>
                    <Input
                      placeholder="Новое событие"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      mt={2}
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days' },
                  { field: 'promotionText5Days' },
                  { field: 'promotionText3Days' },
                  { field: 'promotionText1Day' },
                  { field: 'promotionTextEventDay' },
                  { field: 'promotionTextAfter2Days' }
                ].map(({ field }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <RadioGroup
                            value={item[`promotionType${field.replace('promotionText', '')}`] || 'SMS'}
                            onChange={(value) => handlePromotionTypeChange(value, index, field)}
                            size="sm"
                          >
                            <Stack direction="row" spacing={2}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Пост">Пост</Radio>
                              <Radio value="Статья">Статья</Radio>
                            </Stack>
                          </RadioGroup>
                          <IconButton
                            icon={<FaCopy size="12px" />}
                            aria-label="Copy"
                            ml={2}
                            size="sm"
                            onClick={() => handleCopyText(index, field)}
                            color="gray.500"
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder="Сообщение акции"
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;



*/




/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, RadioGroup, Radio, Stack
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash, FaCopy } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        promotionTypes: [
          action.promotionType7Days || 'SMS',
          action.promotionType5Days || 'SMS',
          action.promotionType3Days || 'SMS',
          action.promotionType1Day || 'SMS',
          action.promotionTypeEventDay || 'SMS',
          action.promotionTypeAfter2Days || 'SMS',
        ],
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const promotionTypeField = `promotionType${field.replace('promotionText', '')}`;
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field,
        promotionType: action[promotionTypeField] || 'SMS',
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Анимация появления текста для конкретного поля
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16);
  };

  const handlePromotionTypeChange = (value, index, field) => {
    const newData = [...data];
    newData[index][`promotionType${field.replace('promotionText', '')}`] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleCopyText = (index, field) => {
    const text = data[index][field] === 'none' ? '' : data[index][field];
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'Текст скопирован',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error('Ошибка копирования текста:', err);
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название события</Th>
            <Th color="green.200">Текст акции за 7 дней до</Th>
            <Th color="green.200">Текст акции за 5 дней до</Th>
            <Th color="green.200">Текст акции за 3 дня до</Th>
            <Th color="green.200">Текст акции за 1 день до</Th>
            <Th color="green.200">Текст акции в день праздника</Th>
            <Th color="green.200">Текст акции после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item.eventDate)}
                          onChange={(date) => handleDateChange(date, index, 'eventDate')}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                        />
                      </Box>
                    </Box>
                    <Input
                      placeholder="Новое событие"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      mt={2}
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days', offset: -7 },
                  { field: 'promotionText5Days', offset: -5 },
                  { field: 'promotionText3Days', offset: -3 },
                  { field: 'promotionText1Day', offset: -1 },
                  { field: 'promotionTextEventDay', offset: 0 },
                  { field: 'promotionTextAfter2Days', offset: 2 }
                ].map(({ field }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <RadioGroup
                            value={item[`promotionType${field.replace('promotionText', '')}`] || 'SMS'}
                            onChange={(value) => handlePromotionTypeChange(value, index, field)}
                            size="sm"
                          >
                            <Stack direction="row" spacing={2}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Пост">Пост</Radio>
                              <Radio value="Статья">Статья</Radio>
                            </Stack>
                          </RadioGroup>
                          <IconButton
                            icon={<FaCopy size="12px" />}
                            aria-label="Copy"
                            ml={2}
                            size="sm"
                            onClick={() => handleCopyText(index, field)}
                            color="gray.500"
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder="Сообщение акции"
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;
*/



/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, RadioGroup, Radio, Stack
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash, FaCopy } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения.:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16);
  };

  const handlePromotionTypeChange = (value, index, field) => {
    const newData = [...data];
    newData[index][`promotionType${field.replace('promotionText', '')}`] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleCopyText = (index, field) => {
    const text = data[index][field] === 'none' ? '' : data[index][field];
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'Текст скопирован',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(err => {
        console.error('Ошибка копирования текста:', err);
        toast({
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название события</Th>
            <Th color="green.200">Текст акции за 7 дней до</Th>
            <Th color="green.200">Текст акции за 5 дней до</Th>
            <Th color="green.200">Текст акции за 3 дня до</Th>
            <Th color="green.200">Текст акции за 1 день до</Th>
            <Th color="green.200">Текст акции в день праздника</Th>
            <Th color="green.200">Текст акции после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item.eventDate)}
                          onChange={(date) => handleDateChange(date, index, 'eventDate')}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                        />
                      </Box>
                    </Box>
                    <Input
                      placeholder="Новое событие"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      mt={2}
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days', offset: -7 },
                  { field: 'promotionText5Days', offset: -5 },
                  { field: 'promotionText3Days', offset: -3 },
                  { field: 'promotionText1Day', offset: -1 },
                  { field: 'promotionTextEventDay', offset: 0 },
                  { field: 'promotionTextAfter2Days', offset: 2 }
                ].map(({ field }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <RadioGroup
                            value={item[`promotionType${field.replace('promotionText', '')}`] || 'SMS'}
                            onChange={(value) => handlePromotionTypeChange(value, index, field)}
                            size="sm"
                          >
                            <Stack direction="row" spacing={2}>
                              <Radio value="SMS">SMS</Radio>
                              <Radio value="Пост">Пост</Radio>
                              <Radio value="Статья">Статья</Radio>
                            </Stack>
                          </RadioGroup>
                          <IconButton
                            icon={<FaCopy size="12px" />}
                            aria-label="Copy"
                            ml={2}
                            size="sm"
                            onClick={() => handleCopyText(index, field)}
                            color="gray.500"
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder="Сообщение акции"
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;
*/


//Самый рабочий 28 sen 2024
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, useBoolean
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Событие удалено',
        description: 'Событие успешно удалено.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Ошибка удаления события:', error);
      toast({
        title: 'Ошибка',
        description: 'Произошла ошибка при удалении события.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Событие обновлено:', response.data))
      .catch(error => console.error('Ошибка обновления события:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Ошибка создания промо-сообщения:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Ошибка при повторном создании промо-сообщения.:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название события</Th>
            <Th color="green.200">Текст акции для сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции для сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции для сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции для сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции для сообщения в день праздника</Th>
            <Th color="green.200">Текст акции для сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {data.map((item, index) => (
              <motion.tr
                key={item._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                <Td>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <IconButton
                        icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                        aria-label="Generate"
                        mr={2}
                        size="sm"
                        onClick={() => generatePromoMessages(index)}
                        color="purple.500"
                        isDisabled={loadingFields[`${index}-generate`]}
                      />
                      <IconButton
                        icon={<FaEdit size="12px" />}
                        aria-label="Edit"
                        mr={2}
                        size="sm"
                        onClick={() => handleOpenModal(index)}
                        color="green.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item.eventDate)}
                          onChange={(date) => handleDateChange(date, index, 'eventDate')}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                        />
                      </Box>
                    </Box>
                    <Input
                      placeholder="Новое событие"
                      value={item.eventName === 'none' ? '' : item.eventName}
                      onChange={(e) => handleTextChange(e, index, 'eventName')}
                      onBlur={(e) => handleBlur(e, index, 'eventName')}
                      size="sm"
                      mt={2}
                      border="none"
                      color="red.500"
                      backgroundColor="transparent"
                      p={0}
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
                {[
                  { field: 'promotionText7Days', offset: -7 },
                  { field: 'promotionText5Days', offset: -5 },
                  { field: 'promotionText3Days', offset: -3 },
                  { field: 'promotionText1Day', offset: -1 },
                  { field: 'promotionTextEventDay', offset: 0 },
                  { field: 'promotionTextAfter2Days', offset: 2 }
                ].map(({ field, offset }, colIndex) => (
                  <Td key={colIndex}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <IconButton
                          icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                          aria-label="Regenerate"
                          mr={2}
                          size="sm"
                          onClick={() => regenerateSingleMessage(index, field)}
                          color="blue.500"
                          isDisabled={loadingFields[`${index}-${field}`]}
                        />
                        <Box width="120px">
                          <DatePicker
                            selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                            onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                            dateFormat="dd.MM.yyyy"
                            customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                          />
                        </Box>
                      </Box>
                      <Textarea
                        value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                        onChange={(e) => handleTextChange(e, index, field)}
                        onBlur={(e) => handleBlur(e, index, field)}
                        placeholder="Сообщение акции"
                        size="sm"
                        height="40px"
                        border="none"
                        p={0}
                        backgroundColor="transparent"
                        _focus={{ boxShadow: "none" }}
                      />
                    </Box>
                  </Td>
                ))}
                <Td>
                  <IconButton
                    icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                    aria-label="Delete"
                    size="sm"
                    onClick={() => handleRemoveRow(index)}
                    color="red.200"
                    isDisabled={loadingFields[`${index}-delete`]}
                  />
                </Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;


*/











/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast, useBoolean
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { motion } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Row deleted',
        description: 'The row has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Error deleting action:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the row.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Error generating promo messages:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Error regenerating promo message:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <motion.tr
              key={item._id}
              initial={{ opacity: 1, x: 0 }}
              animate={{ opacity: loadingFields[`${index}-delete`] ? 0 : 1, x: loadingFields[`${index}-delete`] ? -100 : 0 }}
              transition={{ duration: 0.5 }}
            >
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                      isDisabled={loadingFields[`${index}-generate`]}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => regenerateSingleMessage(index, field)}
                        color="blue.500"
                        isDisabled={loadingFields[`${index}-${field}`]}
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                  isDisabled={loadingFields[`${index}-delete`]}
                />
              </Td>
            </motion.tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




//база данных, gpt generator, анимация текст, состояние кнопок генерации и анимация удаления строки
//src/pages/tabs/ActionGenerateTab.jsx
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner, useToast
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { motion } from 'framer-motion';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});
  const toast = useToast();

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = async (index) => {
    const id = data[index]._id;
    setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: true }));

    try {
      await axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true });
      toast({
        title: 'Row deleted',
        description: 'The row has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      const updatedData = data.filter((_, i) => i !== index);
      setData(updatedData);
      setHolidays(updatedData);
    } catch (error) {
      console.error('Error deleting action:', error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the row.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-delete`]: false }));
    }
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Error generating promo messages:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Error regenerating promo message:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <motion.tr
              key={item._id}
              initial={{ opacity: 1, x: 0 }}
              animate={{ opacity: loadingFields[`${index}-delete`] ? 0 : 1, x: loadingFields[`${index}-delete`] ? -100 : 0 }}
              transition={{ duration: 0.5 }}
            >
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                      isDisabled={loadingFields[`${index}-generate`]}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => regenerateSingleMessage(index, field)}
                        color="blue.500"
                        isDisabled={loadingFields[`${index}-${field}`]}
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={loadingFields[`${index}-delete`] ? <Spinner size="sm" /> : <FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                  isDisabled={loadingFields[`${index}-delete`]}
                />
              </Td>
            </motion.tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/






//база данных, gpt generator, анимация текст и состояние кнопок генерации 
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Spinner
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});
  const [loadingFields, setLoadingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });
    } catch (error) {
      console.error('Error generating promo messages:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-generate`]: false }));
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: true }));
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);
    } catch (error) {
      console.error('Error regenerating promo message:', error);
    } finally {
      setLoadingFields(prev => ({ ...prev, [`${index}-${field}`]: false }));
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = text.substring(0, charIndex + 1);
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={loadingFields[`${index}-generate`] ? <Spinner size="sm" /> : <FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                      isDisabled={loadingFields[`${index}-generate`]}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={loadingFields[`${index}-${field}`] ? <Spinner size="sm" /> : <FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => regenerateSingleMessage(index, field)}
                        color="blue.500"
                        isDisabled={loadingFields[`${index}-${field}`]}
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/



//рабочий полный фарш но иногда тупит перегенерация текста (без состояния кнопкок генерации)
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });

    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const regenerateSingleMessage = async (index, field) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/regeneratePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id,
        field
      }, { withCredentials: true });

      const newData = [...data];
      newData[index][field] = response.data[field];
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance for the specific field
      animateText(index, field, response.data[field]);

    } catch (error) {
      console.error('Error regenerating promo message:', error);
    }
  };

  const animateText = (index, field, text) => {
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = (prev[`${index}-${field}`] || '') + text[charIndex];
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => regenerateSingleMessage(index, field)}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/






//база данных, gpt generator и анимация
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi,
        actionId: action._id // Передача actionId
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });

    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const animateText = (index, field, text) => {
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = (prev[`${index}-${field}`] || '') + text[charIndex];
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */






//Боллее быстрая анимация текста 
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });

    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const animateText = (index, field, text) => {
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = (prev[`${index}-${field}`] || '') + text[charIndex];
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 16); // Speed up the animation 3 times (50ms / 3 ≈ 16ms)
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





//с анимацией и поддерживает смайлики
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });

    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const animateText = (index, field, text) => {
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = (prev[`${index}-${field}`] || '') + text[charIndex];
        if (charIndex >= text.length - 1) {
          clearInterval(intervalId);
        }
        charIndex += 1;
        return { ...prev, [`${index}-${field}`]: newText };
      });
    }, 50);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */







//С анимацией текстовых полей - работает но тупая 
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const [animatingFields, setAnimatingFields] = useState({});

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);

      // Animate the text appearance
      ['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].forEach((field) => {
        animateText(index, field, response.data[field]);
      });

    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const animateText = (index, field, text) => {
    setAnimatingFields(prev => ({ ...prev, [`${index}-${field}`]: '' }));
    let charIndex = 0;
    const intervalId = setInterval(() => {
      setAnimatingFields(prev => {
        const newText = prev[`${index}-${field}`] + text[charIndex];
        return { ...prev, [`${index}-${field}`]: newText };
      });
      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(intervalId);
      }
    }, 50);
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea
                      value={animatingFields[`${index}-${field}`] !== undefined ? animatingFields[`${index}-${field}`] : (item[field] === 'none' ? '' : item[field])}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции"
                      size="sm"
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





//база данных и генерация сообщений через gpt
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const generatePromoMessages = async (index) => {
    const action = data[index];
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/generatePromoMessage`, {
        eventName: action.eventName,
        eventDate: action.eventDate,
        commentForAi: action.commentForAi
      }, { withCredentials: true });

      const updatedAction = { ...action, ...response.data };
      const newData = [...data];
      newData[index] = updatedAction;
      setData(newData);
      setHolidays(newData);
    } catch (error) {
      console.error('Error generating promo messages:', error);
    }
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => generatePromoMessages(index)}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */







//таблица подключенная к базе данных - рабочая но без gtp-4o
/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const formatDate = (date) => {
  const d = new Date(date);
  const day = (`0${d.getDate()}`).slice(-2);
  const month = (`0${d.getMonth() + 1}`).slice(-2);
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      // Automatically update other dates based on the main date
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  const CustomDateInput = ({ value, onClick }) => (
    <Input
      size="sm"
      width="120px"
      textAlign="right"
      readOnly
      fontFamily="monospace"
      border="none"
      color="gray.500"
      backgroundColor="transparent"
      _focus={{ boxShadow: "none" }}
      value={value}
      onClick={onClick}
    />
  );

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        dateFormat="dd.MM.yyyy"
                        customInput={<CustomDateInput value={formatDate(new Date(item.eventDate))} />}
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          dateFormat="dd.MM.yyyy"
                          customInput={<CustomDateInput value={formatDate(new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset)))} />}
                        />
                      </Box>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setData(holidays);
  }, [holidays]);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      // Automatically update other dates based on the main date
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setHolidays(updatedData);
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          customInput={
                            <Input 
                              size="sm" 
                              width="120px" 
                              textAlign="right" 
                              fontFamily="monospace" 
                              border="none"
                              color="gray.500"
                              backgroundColor="transparent"
                              _focus={{ boxShadow: "none" }}
                            />
                          }
                        />
                      </Box>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;

    if (field === 'eventDate') {
      // Automatically update other dates based on the main date
      newData[index].sendingDate7Days = getDateWithOffset(date, -7);
      newData[index].sendingDate5Days = getDateWithOffset(date, -5);
      newData[index].sendingDate3Days = getDateWithOffset(date, -3);
      newData[index].sendingDate1Day = getDateWithOffset(date, -1);
      newData[index].sendingDateEventDay = date;
      newData[index].sendingDateAfter2Days = getDateWithOffset(date, 2);
    }

    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        setData(data.filter((_, i) => i !== index));
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: getDateWithOffset(new Date(), -7),
      promotionText5Days: 'none',
      sendingDate5Days: getDateWithOffset(new Date(), -5),
      promotionText3Days: 'none',
      sendingDate3Days: getDateWithOffset(new Date(), -3),
      promotionText1Day: 'none',
      sendingDate1Day: getDateWithOffset(new Date(), -1),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: getDateWithOffset(new Date(), 2)
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setData([...data, response.data]);
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          customInput={
                            <Input 
                              size="sm" 
                              width="120px" 
                              textAlign="right" 
                              fontFamily="monospace" 
                              border="none"
                              color="gray.500"
                              backgroundColor="transparent"
                              _focus={{ boxShadow: "none" }}
                            />
                          }
                        />
                      </Box>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleDateChange = (date, index, field) => {
    const newData = [...data];
    newData[index][field] = date;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        setData(data.filter((_, i) => i !== index));
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: new Date(),
      promotionText5Days: 'none',
      sendingDate5Days: new Date(),
      promotionText3Days: 'none',
      sendingDate3Days: new Date(),
      promotionText1Day: 'none',
      sendingDate1Day: new Date(),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: new Date()
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setData([...data, response.data]);
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate;
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 день до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index, 'eventDate')}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    placeholder="Новое событие"
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[
                { field: 'promotionText7Days', offset: -7 },
                { field: 'promotionText5Days', offset: -5 },
                { field: 'promotionText3Days', offset: -3 },
                { field: 'promotionText1Day', offset: -1 },
                { field: 'promotionTextEventDay', offset: 0 },
                { field: 'promotionTextAfter2Days', offset: 2 }
              ].map(({ field, offset }, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Box width="120px">
                        <DatePicker
                          selected={new Date(item[`sendingDate${field.replace('promotionText', '')}`] || getDateWithOffset(item.eventDate, offset))}
                          onChange={(date) => handleDateChange(date, index, `sendingDate${field.replace('promotionText', '')}`)}
                          customInput={
                            <Input 
                              size="sm" 
                              width="120px" 
                              textAlign="right" 
                              fontFamily="monospace" 
                              border="none"
                              color="gray.500"
                              backgroundColor="transparent"
                              _focus={{ boxShadow: "none" }}
                            />
                          }
                        />
                      </Box>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */




/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].eventDate = date;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi === 'none' ? '' : data[index].commentForAi);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment === '' ? 'none' : comment;
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        setData(data.filter((_, i) => i !== index));
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    const value = e.target.value === '' ? 'none' : e.target.value;
    newData[index][field] = value;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleBlur = (e, index, field) => {
    if (e.target.value === '') {
      const newData = [...data];
      newData[index][field] = 'none';
      setData(newData);
      updateAction(newData[index]);
    }
  };

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: new Date(),
      promotionText5Days: 'none',
      sendingDate5Days: new Date(),
      promotionText3Days: 'none',
      sendingDate3Days: new Date(),
      promotionText1Day: 'none',
      sendingDate1Day: new Date(),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: new Date()
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setData([...data, response.data]);
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.eventName === 'none' ? '' : item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    onBlur={(e) => handleBlur(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].map((field, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.eventDate, -[7, 5, 3, 1, 0, 2][colIndex])}</Text>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      onBlur={(e) => handleBlur(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/





/*import React, { useState, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const ActionGenerateTag = ({ holidays, setHolidays }) => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    axios.get(`${BACKEND_DOMAIN}/tableActions`, { withCredentials: true })
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].eventDate = date;
    setData(newData);
    updateAction(newData[index]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    setComment(data[index].commentForAi || '');
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    const newData = [...data];
    newData[selectedIndex].commentForAi = comment || 'none';
    setData(newData);
    updateAction(newData[selectedIndex]);
    onClose();
  };

  const handleRemoveRow = (index) => {
    const id = data[index]._id;
    axios.delete(`${BACKEND_DOMAIN}/tableActions/${id}`, { withCredentials: true })
      .then(() => {
        setData(data.filter((_, i) => i !== index));
      })
      .catch(error => console.error('Error deleting action:', error));
  };

  const handleTextChange = (e, index, field) => {
    const newData = [...data];
    newData[index][field] = e.target.value || 'none';
    setData(newData);
    updateAction(newData[index]);
  };

  const handleAddHoliday = () => {
    const newAction = {
      eventName: 'New Event',
      eventDate: new Date(),
      commentForAi: 'none',
      promotionText7Days: 'none',
      sendingDate7Days: new Date(),
      promotionText5Days: 'none',
      sendingDate5Days: new Date(),
      promotionText3Days: 'none',
      sendingDate3Days: new Date(),
      promotionText1Day: 'none',
      sendingDate1Day: new Date(),
      promotionTextEventDay: 'none',
      sendingDateEventDay: new Date(),
      promotionTextAfter2Days: 'none',
      sendingDateAfter2Days: new Date()
    };

    axios.post(`${BACKEND_DOMAIN}/tableActions`, newAction, { withCredentials: true })
      .then(response => {
        setData([...data, response.data]);
        setHolidays([...holidays, response.data]);
      })
      .catch(error => console.error('Error adding action:', error));
  };

  const updateAction = (action) => {
    axios.put(`${BACKEND_DOMAIN}/tableActions/${action._id}`, action, { withCredentials: true })
      .then(response => console.log('Action updated:', response.data))
      .catch(error => console.error('Error updating action:', error));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={item._id}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={new Date(item.eventDate)}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.eventName}
                    onChange={(e) => handleTextChange(e, index, 'eventName')}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {['promotionText7Days', 'promotionText5Days', 'promotionText3Days', 'promotionText1Day', 'promotionTextEventDay', 'promotionTextAfter2Days'].map((field, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.eventDate, -[7, 5, 3, 1, 0, 2][colIndex])}</Text>
                    </Box>
                    <Textarea 
                      value={item[field] === 'none' ? '' : item[field]}
                      onChange={(e) => handleTextChange(e, index, field)}
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/
















/* Последний рабочий с демо данными
// src/pages/tabs/ActionGenerateTag.jsx
import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaTrash } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTag = ({ holidays }) => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    setData([...initialData, ...holidays]);
  }, [holidays]);

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const handleRemoveRow = (index) => {
    setData(data.filter((_, i) => i !== index));
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th> 
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th> 
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th> 
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
            <Th color="green.200"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
              <Td>
                <IconButton
                  icon={<FaTrash size="12px" />}
                  aria-label="Delete"
                  size="sm"
                  onClick={() => handleRemoveRow(index)}
                  color="red.200"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTag = ({ holidays }) => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    setData([...initialData, ...holidays]);
  }, [holidays]);

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/






/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Flex
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTag = ({ handleAddHoliday }) => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/





/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Flex
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaPlus } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTab = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Flex justifyContent="center" mt={4}>
        <Button
          leftIcon={<FaPlus />}
          size="md"
          onClick={handleAddHoliday}
          colorScheme="teal"
          variant="solid"
          width="full"
          maxW="md"
        >
          + Добавить событие
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTab; */




/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Flex
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaPlus } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
          <Tr>
            <Td colSpan={7} textAlign="left">
              <Button
                leftIcon={<FaPlus />}
                size="md"
                onClick={handleAddHoliday}
                colorScheme="teal"
                variant="solid"
              >
                + Добавить событие
              </Button>
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */


/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaPlus } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
          <Tr>
            <Td colSpan={7} textAlign="center">
              <IconButton
                icon={<FaPlus size="20px" />}
                aria-label="Add"
                size="lg"
                onClick={handleAddHoliday}
                colorScheme="teal"
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0) }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th color="yellow.200">Название праздника</Th>
            <Th color="green.200">Текст акции сообщения за 7 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 5 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 3 дней до</Th>
            <Th color="green.200">Текст акции сообщения за 1 дней до</Th>
            <Th color="green.200">Текст акции сообщения в день праздника</Th>
            <Th color="green.200">Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/



/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0) }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                      color="purple.500"
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                      color="green.500"
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                        color="blue.500"
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/





/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1) },
  { name: "День матери", date: new Date(2024, 10, 24) },
  { name: "Новый год", date: new Date(2024, 11, 31) },
  { name: "1 января", date: new Date(2024, 0, 1) },
  { name: "Рождество", date: new Date(2024, 0, 7) },
  { name: "Пасха", date: new Date(2024, 4, 5) },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0) }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date() }]);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm" color="gray.500">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */




/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                      <IconButton
                        icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                        aria-label="Toggle Lock"
                        size="sm"
                        ml={2}
                        onClick={() => handleToggleLock(index, colIndex)}
                      />
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      height="40px"
                      border="none"
                      p={0}
                      backgroundColor="transparent"
                      _focus={{ boxShadow: "none" }}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="120px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="120px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    p={0}
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                      <IconButton
                        icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                        aria-label="Toggle Lock"
                        size="sm"
                        ml={2}
                        onClick={() => handleToggleLock(index, colIndex)}
                      />
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      height="40px"
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/






/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="100px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="100px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                            border="none"
                            color="gray.500"
                            backgroundColor="transparent"
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    border="none"
                    color="red.500"
                    backgroundColor="transparent"
                    _focus={{ boxShadow: "none" }}
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                      <IconButton
                        icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                        aria-label="Toggle Lock"
                        size="sm"
                        ml={2}
                        onClick={() => handleToggleLock(index, colIndex)}
                      />
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      height="40px"
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="100px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="100px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Input
                    value={item.name}
                    onChange={(e) => {
                      const newData = [...data];
                      newData[index].name = e.target.value;
                      setData(newData);
                    }}
                    size="sm"
                    mt={2}
                    borderColor="red.500"
                  />
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                      <IconButton
                        icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                        aria-label="Toggle Lock"
                        size="sm"
                        ml={2}
                        onClick={() => handleToggleLock(index, colIndex)}
                      />
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      height="40px"
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/




/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="100px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="100px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Text color="red.500" mt={2}>{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                      <IconButton
                        icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                        aria-label="Toggle Lock"
                        size="sm"
                        ml={2}
                        onClick={() => handleToggleLock(index, colIndex)}
                      />
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      height="40px"
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */


/*import React, { useState, useRef } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');
  const inputRef = useRef();

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Box width="100px">
                      <DatePicker
                        selected={item.date}
                        onChange={(date) => handleDateChange(date, index)}
                        customInput={
                          <Input 
                            size="sm" 
                            width="100px" 
                            textAlign="right" 
                            readOnly 
                            fontFamily="monospace" 
                            ref={inputRef}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Text color="red.500" mt={2}>{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      mb={2}
                      height="40px"
                    />
                    <IconButton
                      icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                      aria-label="Toggle Lock"
                      size="sm"
                      onClick={() => handleToggleLock(index, colIndex)}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





/*import React, { useState } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    // Сохранение комментария
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <DatePicker
                      selected={item.date}
                      onChange={(date) => handleDateChange(date, index)}
                      customInput={
                        <Input 
                          size="sm" 
                          width="100px" 
                          textAlign="right" 
                          readOnly 
                          fontFamily="monospace"
                        />
                      }
                    />
                  </Box>
                  <Text color="red.500" mt={2}>{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      mb={2}
                      height="40px"
                    />
                    <IconButton
                      icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                      aria-label="Toggle Lock"
                      size="sm"
                      onClick={() => handleToggleLock(index, colIndex)}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */




/*import React, { useState } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    // Сохранение комментария
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <DatePicker
                      selected={item.date}
                      onChange={(date) => handleDateChange(date, index)}
                      customInput={<Input size="sm" width="100px" textAlign="right" readOnly />}
                    />
                  </Box>
                  <Text color="red.500" mt={2}>{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      mb={2}
                      height="40px"
                    />
                    <IconButton
                      icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                      aria-label="Toggle Lock"
                      size="sm"
                      onClick={() => handleToggleLock(index, colIndex)}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag; */





/*import React, { useState } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    // Сохранение комментария
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <IconButton
                      icon={<FaEdit size="12px" />}
                      aria-label="Edit"
                      mr={2}
                      size="sm"
                      onClick={() => handleOpenModal(index)}
                    />
                    <Input 
                      value={item.date.toLocaleDateString('ru-RU')} 
                      readOnly 
                      size="sm"
                      width="100px"
                      textAlign="right"
                      onClick={() => handleDateChange(new Date(), index)} // Добавить реальную обработку даты
                    />
                  </Box>
                  <Text color="red.500" mt={2}>{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      mb={2}
                      height="40px"
                    />
                    <IconButton
                      icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                      aria-label="Toggle Lock"
                      size="sm"
                      onClick={() => handleToggleLock(index, colIndex)}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/






/*import React, { useState } from 'react';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Input, Textarea, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text
} from '@chakra-ui/react';
import { FaMagic, FaEdit, FaSync, FaLock, FaUnlock } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initialData = [
  { name: "День знаний", date: new Date(2024, 8, 1), locked: false },
  { name: "День матери", date: new Date(2024, 10, 24), locked: false },
  { name: "Новый год", date: new Date(2024, 11, 31), locked: false },
  { name: "1 января", date: new Date(2024, 0, 1), locked: false },
  { name: "Рождество", date: new Date(2024, 0, 7), locked: false },
  { name: "Пасха", date: new Date(2024, 4, 5), locked: false },
  { name: "Возможность добавить собственный праздник", date: new Date(0, 0, 0), locked: false }
];

const ActionGenerateTag = () => {
  const [data, setData] = useState(initialData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [comment, setComment] = useState('');

  const handleDateChange = (date, index) => {
    const newData = [...data];
    newData[index].date = date;
    setData(newData);
  };

  const handleAddHoliday = () => {
    setData([...data, { name: "Новый праздник", date: new Date(), locked: false }]);
  };

  const handleToggleLock = (index, colIndex) => {
    const newData = [...data];
    newData[index].locked = !newData[index].locked;
    setData(newData);
  };

  const handleOpenModal = (index) => {
    setSelectedIndex(index);
    onOpen();
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSaveComment = () => {
    // Сохранение комментария
    onClose();
  };

  const getDateWithOffset = (date, offset) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + offset);
    return newDate.toLocaleDateString('ru-RU');
  };

  return (
    <Box p={5}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Название праздника</Th>
            <Th>Текст акции сообщения за 7 дней до</Th>
            <Th>Текст акции сообщения за 5 дней до</Th>
            <Th>Текст акции сообщения за 3 дней до</Th>
            <Th>Текст акции сообщения за 1 дней до</Th>
            <Th>Текст акции сообщения в день праздника</Th>
            <Th>Текст акции сообщения после 2 дней</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <IconButton
                      icon={<FaMagic size="12px" />}
                      aria-label="Generate"
                      mr={2}
                      size="sm"
                      onClick={() => alert('Генерация текста акций для всей строки')}
                    />
                    <Input 
                      value={item.date.toLocaleDateString('ru-RU')} 
                      readOnly 
                      size="sm" 
                      onClick={() => handleOpenModal(index)}
                      width="80px"
                    />
                  </Box>
                  <Text color="red.500">{item.name}</Text>
                </Box>
              </Td>
              {[7, 5, 3, 1, 0, 2].map((offset, colIndex) => (
                <Td key={colIndex}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" mb={2}>
                      <IconButton
                        icon={<FaSync size="12px" />}
                        aria-label="Regenerate"
                        mr={2}
                        size="sm"
                        onClick={() => alert('Повторная генерация текста акции')}
                      />
                      <Text fontSize="sm">{getDateWithOffset(item.date, -offset)}</Text>
                    </Box>
                    <Textarea 
                      placeholder="Сообщение акции" 
                      isReadOnly={item.locked} 
                      size="sm" 
                      mb={2}
                      height="40px"
                    />
                    <IconButton
                      icon={item.locked ? <FaUnlock size="12px" /> : <FaLock size="12px" />}
                      aria-label="Toggle Lock"
                      size="sm"
                      onClick={() => handleToggleLock(index, colIndex)}
                    />
                  </Box>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Комментарий по акции для праздника</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea value={comment} onChange={handleCommentChange} placeholder="Введите комментарий" />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveComment}>
              Сохранить
            </Button>
            <Button variant="ghost" onClick={onClose}>Отмена</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ActionGenerateTag;*/
