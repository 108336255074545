// src/pages/tabs/AddNewAction.jsx
import React from 'react';
import { Button, Spinner } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { useBoolean } from '@chakra-ui/react';

const AddNewAction = ({ handleAddHoliday }) => {
  const [isLoading, setIsLoading] = useBoolean();

  const handleClick = async () => {
    setIsLoading.on();
    await handleAddHoliday();
    setIsLoading.off();
  };

  return (
    <Button
      leftIcon={isLoading ? <Spinner size="sm" /> : <FaPlus />}
      size="md"
      onClick={handleClick}
      colorScheme="yellow"
      variant="outline"
      width="full"
      borderColor="yellow.200"
      borderStyle="dashed"
      borderWidth="1px"
      color="yellow.200"
      mb={20}
      isDisabled={isLoading}
    >
      {isLoading ? 'Добавление...' : 'Добавить тему'}
    </Button>
  );
};

export default AddNewAction;




/*//src/pages/tabs/AddNewAction.jsx
import React from 'react';
import { Button } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

const AddNewAction = ({ handleAddHoliday }) => {
  return (
    <Button
      leftIcon={<FaPlus />}
      size="md"
      onClick={handleAddHoliday}
      colorScheme="yellow"
      variant="outline"
      width="full"
      borderColor="yellow.200"
      borderStyle="dashed"
      borderWidth="1px"
      color="yellow.200"
      mb={20}
    >
      Добавить событие
    </Button>
  );
};

export default AddNewAction;*/






/*import React from 'react';
import { Button } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

const AddNewAction = ({ handleAddHoliday }) => {
  return (
    <Button
      leftIcon={<FaPlus />}
      size="md"
      onClick={handleAddHoliday}
      colorScheme="teal"
      variant="solid"
      width="full"
      maxW="md"
    >
      + Добавить событие
    </Button>
  );
};

export default AddNewAction;*/
