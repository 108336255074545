// src/pages/TransactionsPage.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  IconButton,
  RadioGroup,
  Radio,
  Stack,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const TransactionsPage = ({ onClose }) => {
  const [balance, setBalance] = useState(null); // Доступный лимит символов
  const [transactions, setTransactions] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState('250000'); // По умолчанию первый тариф
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Добавляем состояние для загрузки
  const toast = useToast();


  useEffect(() => {
    // Получаем информацию о лимитах символов
    axios
      .get(`${BACKEND_DOMAIN}/characterUsage`, { withCredentials: true })
      .then((response) => {
        const { totalCharacterLimit, usedCharacters } = response.data;
        setBalance(totalCharacterLimit - usedCharacters);
      })
      .catch((error) => {
        console.error('Ошибка при получении лимита символов:', error);
      });

    // Получаем историю платежей
    axios
      .get(`${BACKEND_DOMAIN}/payments`, { withCredentials: true })
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении истории платежей:', error);
      });

    // Получаем userEmail
    axios
      .get(`${BACKEND_DOMAIN}/userEmail`, { withCredentials: true })
      .then((response) => {
        setUserEmail(response.data.userEmail);
      })
      .catch((error) => {
        console.error('Ошибка при получении userEmail:', error);
      });
  }, []);

  const balanceColor =
    balance > 100
      ? 'white'
      : balance > 30 && balance <= 100
      ? 'orange.400'
      : 'red.500';




      const handleTopUp = () => {
        setIsLoading(true); // Устанавливаем состояние загрузки
        const tariffOptions = {
          '250000': { amount: 500, description: '250 тыс. символов за 500 руб.' },
          '500000': { amount: 1000, description: '500 тыс. символов за 1000 руб.' },
          '1000000': { amount: 2000, description: '1 млн. символов за 2000 руб.' },
        };
      
        const selectedOption = tariffOptions[selectedTariff];
      
        axios
          .post(
            `${BACKEND_DOMAIN}/createPayment`,
            {
              amount: selectedOption.amount,
              charactersPurchased: parseInt(selectedTariff, 10),
              tariff: selectedOption.description,
            },
            { withCredentials: true }
          )
          .then((response) => {
            const { confirmationUrl } = response.data;
            window.location.href = confirmationUrl;
          })
          .catch((error) => {
            console.error('Ошибка при создании платежа:', error);
            toast({
              title: 'Ошибка',
              description: 'Не удалось создать платеж',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          })
          .finally(() => {
            //setIsLoading(false); // Сбрасываем состояние загрузки
          });
      };
      
      
  /*const handleTopUp = () => {
    const tariffOptions = {
      '250000': '250k. simvolov za 500 rub.',
      '500000': '500k. simvolov za 1000 rub.',
      '1000000': '1 mln. simvolov za 2000 rub.',
    };

    const message = `Pokupka simvolov po tarifu "${tariffOptions[selectedTariff]}" dlya akkaunta "${userEmail}"`;

    const telegramWebUrl = `https://t.me/novergeme?text=${encodeURIComponent(
      message
    )}`;
    const decodedMessage = decodeURIComponent(telegramWebUrl);

    window.open(decodedMessage, '_blank');
  }; */

  return (
    <Box p={8} width="100%">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb="12"
        mt="4"
      >
        {balance === null ? (
          <Spinner size="md" />
        ) : (
          <>
            <Text fontSize="16px" fontWeight="bold" mr="8" color={balanceColor}>
              Лимит символов: {balance}
            </Text>
            {balance < 30000 && (
              <Text fontSize="14px" color="red.500" mt="2">
                Вашего баланса хватит меньше чем на 6 статей, пополните баланс
              </Text>
            )}
          </>
        )}
      </Box>

      <Text fontSize="14px" mb="4" textAlign="left">
        Выберите тариф:
      </Text>

      <RadioGroup
        onChange={setSelectedTariff}
        value={selectedTariff}
        mb="4"
        colorScheme="green"
      >
        <Stack direction="column">
          <Radio value="250000">250 тыс. символов за 500 руб.</Radio>
          <Radio value="500000">500 тыс. символов за 1000 руб.</Radio>
          <Radio value="1000000">1 млн. символов за 2000 руб.</Radio>
        </Stack>
      </RadioGroup>

      <Button
        colorScheme="green"
        onClick={handleTopUp}
        mb="8"
        isLoading={isLoading} // Используем индикатор загрузки кнопки
        loadingText="Открываю кассу..."
        disabled={isLoading} // Блокируем кнопку при загрузке
      >
        Пополнить
      </Button>

      <Text fontSize="14px" mb="4" textAlign="left">
        История пополнений:
      </Text>

      <Box
        overflowY="auto"
        overflowX="hidden"
        height="300px"
        border="1px dashed"
        borderColor="#5DA8DE"
        borderRadius="3px"
        p="4"
        width="100%"
      >
        <Grid templateColumns="3fr 2fr 2fr 3fr 2fr" gap="3" mb="4">
          <Text fontWeight="bold">Дата и время оплаты</Text>
          <Text fontWeight="bold">Сумма</Text>
          <Text fontWeight="bold">Валюта</Text>
          <Text fontWeight="bold">Тариф</Text>
          <Text fontWeight="bold">Куплено символов</Text>
          <Text fontWeight="bold">Статус</Text>
        </Grid>
        {transactions
        .filter((transaction) => transaction.status !== 'pending')
        .map((transaction, index) => (
          <Grid
            key={index}
            templateColumns="3fr 2fr 2fr 3fr 2fr"
            gap="3"
            mb="2"
          >
            <Text>{transaction.date}</Text>
            <Text color="green.500">{transaction.amount}</Text>
            <Text>{transaction.currency}</Text>
            <Text>{transaction.tariff}</Text>
            <Text>{transaction.charactersPurchased}</Text>
            <Text>{transaction.status}</Text>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default TransactionsPage;










//рабочий 4 октября 2024
// src/pages/TransactionsPage.jsx
/*import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  IconButton,
  RadioGroup,
  Radio,
  Stack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import axios from 'axios';

const BACKEND_DOMAIN = process.env.REACT_APP_API_URL;

const TransactionsPage = ({ onClose }) => {
  const [balance, setBalance] = useState(0); // Доступный лимит символов
  const [transactions, setTransactions] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState('250000'); // По умолчанию первый тариф
  const [userEmail, setUserEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    // Получаем информацию о лимитах символов
    axios
      .get(`${BACKEND_DOMAIN}/characterUsage`, { withCredentials: true })
      .then((response) => {
        const { totalCharacterLimit, usedCharacters } = response.data;
        setBalance(totalCharacterLimit - usedCharacters);
      })
      .catch((error) => {
        console.error('Ошибка при получении лимита символов:', error);
      });

    // Получаем историю платежей
    axios
      .get(`${BACKEND_DOMAIN}/payments`, { withCredentials: true })
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении истории платежей:', error);
      });

    // Получаем userEmail
    axios
      .get(`${BACKEND_DOMAIN}/userEmail`, { withCredentials: true })
      .then((response) => {
        setUserEmail(response.data.userEmail);
      })
      .catch((error) => {
        console.error('Ошибка при получении userEmail:', error);
      });
  }, []);

  const balanceColor =
    balance > 100
      ? 'white'
      : balance > 30 && balance < 100
      ? 'orange.400'
      : 'red.500';

  const handleTopUp = () => {
    const tariffOptions = {
      '250000': '250k. simvolov za 500 rub.',
      '500000': '500k. simvolov za 1000 rub.',
      '1000000': '1 mln. simvolov za 2000 rub.',
    };

    const message = `Pokupka simvolov po tarifu "${tariffOptions[selectedTariff]}" dlya akkaunta "${userEmail}"`;

    const telegramWebUrl = `https://t.me/novergeme?text=${encodeURIComponent(message)}`;
    const decodedMessage = decodeURIComponent(telegramWebUrl);

    window.open(decodedMessage, '_blank');
  };

  return (
    <Box p={8} width="100%">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb="12"
        mt="4"
      >
        <Text fontSize="16px" fontWeight="bold" mr="8" color={balanceColor}>
          Лимит символов: {balance}
        </Text>
      </Box>

      <Text fontSize="14px" mb="4" textAlign="left">
        Выберите тариф:
      </Text>

      <RadioGroup
        onChange={setSelectedTariff}
        value={selectedTariff}
        mb="4"
        colorScheme="green"
      >
        <Stack direction="column">
          <Radio value="250000">250 тыс. символов за 500 руб.</Radio>
          <Radio value="500000">500 тыс. символов за 1000 руб.</Radio>
          <Radio value="1000000">1 млн. символов за 2000 руб.</Radio>
        </Stack>
      </RadioGroup>

      <Button colorScheme="green" onClick={handleTopUp} mb="8">
        Пополнить
      </Button>

      <Text fontSize="14px" mb="4" textAlign="left">
        История пополнений:
      </Text>

      <Box
        overflowY="auto"
        overflowX="hidden"
        height="300px"
        border="1px dashed"
        borderColor="#5DA8DE"
        borderRadius="3px"
        p="4"
        width="100%"
      >
        <Grid templateColumns="3fr 2fr 2fr 3fr 2fr" gap="3" mb="4">
          <Text fontWeight="bold">Дата и время оплаты</Text>
          <Text fontWeight="bold">Сумма</Text>
          <Text fontWeight="bold">Валюта</Text>
          <Text fontWeight="bold">Тариф</Text>
          <Text fontWeight="bold">Куплено символов</Text>
        </Grid>
        {transactions.map((transaction, index) => (
          <Grid
            key={index}
            templateColumns="3fr 2fr 2fr 3fr 2fr"
            gap="3"
            mb="2"
          >
            <Text>{transaction.date}</Text>
            <Text color="green.500">{transaction.amount}</Text>
            <Text>{transaction.currency}</Text>
            <Text>{transaction.tariff}</Text>
            <Text>{transaction.charactersPurchased}</Text>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default TransactionsPage;

*/







//Рабочий за 29 сентября 2024
/*import React, { useState } from 'react';
import {
  Box,
  Text,
  Grid,
  IconButton,
  Input,
  Button,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const TransactionsPage = () => {
  const [balance, setBalance] = useState(0); // This will be set up later
  const [transactions, setTransactions] = useState([
    // Test data, replace with real data later
    { date: '2023-11-22 11:34', value: '+$500', },
    { date: '2023-11-21 16:47', value: '+$300', },
    // Add more transactions as needed
  ]);


  const balanceColor = balance > 100 
        ? "white"
        : (balance > 30 && balance < 100) 
            ? "orange.400"
            : "red.500"; 

  return (
    <Box p={8} width="100%">
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        mb="12"
        mt="4"
      >
        <Text fontSize="16px" fontWeight="bold" mr="8" color={balanceColor} >
          Лимит символов: {balance}
        </Text>
        <Button colorScheme="green">Пополнить</Button>
      </Box>

      <Text fontSize="14px" mb="4" textAlign="left">
        История:
      </Text>

      <Box 
  overflowY="auto"
  overflowX="hidden"
  height="300px"
  border="1px dashed" 
  borderColor="#5DA8DE"
  borderRadius="3px"
  p="4"
  width="100%"
>
  <Grid 
    templateColumns="5fr 3fr 2fr" 
    gap="3" 
    mb="4"
  >
    {transactions.map((transaction, index) => (
      <React.Fragment key={index}>
        <Box display="flex" alignItems="center" height="100%">
          <Text>{transaction.date}</Text>
        </Box>
        <Box display="flex" alignItems="center" height="100%">
          <Text color="green.500">{transaction.value}</Text>
        </Box>
        <Box height="100%">
          <IconButton
            aria-label="Download Check"
            icon={<CopyIcon />}
            size="md"
            width="100%"
            onClick={() => {/* Functionality to download check *//*}}
          />
        </Box>
      </React.Fragment>
    ))}
  </Grid>
</Box>



    </Box>
  );
};

export default TransactionsPage;
*/